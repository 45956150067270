const DISCOUNT = {
    UPGRADE: 0.20, // 20% off map upgrades
    UPGRADE_NAME: 'Map update discount',
    UPGRADE_EXPIRES_IN_HOURS: 24 // Stripe checkout sessions expire in 24 hours by default
}

export default class CatalogPromotions {

    static ApplyUpgradeDiscount(price) {
        return this.ApplyDiscount(price, DISCOUNT.UPGRADE);
    }

    static UpgradeDiscount() {
        return 100 * DISCOUNT.UPGRADE;
    }

    static UpgradeDiscountName() {
        return DISCOUNT.UPGRADE_NAME;
    }

    static UpgradeDiscountMinDateBeforeExpiry() {
        return new Date(Date.now() - DISCOUNT.UPGRADE_EXPIRES_IN_HOURS * 60 * 60 * 1000);
    }

    static ApplyDiscount(price, discount) {
        let sale = price * (1.0 - discount);
        return sale.toFixed(2); // Round to 2 decimal places
    }

}
